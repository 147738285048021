import React, { Component } from "react";

import "./UiTextInput.scss";

class UiTextInput extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>
          {this.props.type !== "number" &&
          <input
              id={this.props.id}
              disabled={this.props.disabled}
              placeholder={this.props.placeholder}
              type={this.props.type}
              value={this.props.value}
              className={
                  "text-input" +
                  (this.props.btnText ? " text-right" : "") +
                  (this.props.warnText && this.props.warnText != ""
                      ? " text-input-warn"
                      : "")
              }
              onChange={(e) => {
                  if(this.props.callBack) this.props.callBack(e.target.value)
              }}
              onBlur={(e) => {
                  if(this.props.onBlur) this.props.onBlur(e.target.value);
              }}
          />}

          {this.props.type === "number" &&
          <input
              disabled={this.props.disabled}
              placeholder={this.props.placeholder}
              type={this.props.type}
              value={this.props.value}
              min={this.props.min}
              max={this.props.max}
              className={
                  "text-input" +
                  (this.props.btnText ? " text-right" : "") +
                  (this.props.warnText && this.props.warnText != ""
                      ? " text-input-warn"
                      : "")
              }
              onChange={(e) => {
                  if(this.props.callBack) this.props.callBack(e.target.value)
              }}
              onBlur={(e) => {
                  if(this.props.onBlur) this.props.onBlur(e.target.value);
              }}
          />}
        {this.props.btnText ? (
          <button className="btn" onClick={this.props.onClick}>
            {this.props.btnText}
          </button>
        ) : null}
        {this.props.warnText && this.props.warnText != "" ? (
          <p className="text-input-warn">* {this.props.warnText}</p>
        ) : null}
      </div>
    );
  }
}
export default UiTextInput;
