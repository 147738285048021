import React, { Component } from 'react';

import './UiCheckList.scss';

import checkIcon from '../../assets/images/ui/check.svg';

class UiCheckList extends Component {

  state = {
    activeCheck: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(nProps, nState) {

    if (this.props.activeCheck != undefined && this.props.activeCheck != null && nProps.activeCheck != this.props.activeCheck) {
      this.setState({ activeCheck: this.props.activeCheck });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ activeCheck: val });
    this.props.callBack(val);
  }

  render() {
    var checkList = this.props.checkList.map((item, index) => {
      return (
        <div key={index} className="check-item" onClick={() => this.setValue(item.value)}>
          {this.props.radio ?
            <div className={"check-btn check-btn-radio" + (this.state.activeCheck == item.value ? " active" : "")}>
              {this.state.activeCheck == item.value ?
                <div className="check-btn-inner"></div> : null
              }
            </div>
            :
            <div className={"check-btn" + (this.state.activeCheck == item.value ? " active" : "")}>
              {this.state.activeCheck == item.value ?
                <img src={checkIcon} /> : null
              }
            </div>
          }
          {item.label && item.label != '' ?
            <button className="check-name"><span>{item.label}</span></button> : null
          }
        </div>
      );
    });
    return (
      <div className="check-list">
        {checkList}
      </div>
    );
  }
}
export default UiCheckList;