import React, { Component } from "react";
import ReactDOM from "react-dom";
import Slider, { Range } from "rc-slider";
import { FixedSizeList as List } from 'react-window';

import "./UiCatalog.scss";
import "rc-slider/assets/index.css";

import { retrieveData } from "../../services/Storage";
import Env from "../../services/Env";

class UiCatalogFilter extends Component {
  state = {
    pageTitle: "Спец. предложения"
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    let title = retrieveData("special");
    if(title) {
      this.setState({ pageTitle: title })
    } 
  }

  componentDidUpdate(prevProps , prevState){
    if(prevState.pageTitle !== retrieveData("special")) this.setState({ pageTitle: retrieveData("special") })
  }

  render() {
    return (
      <div className="mobile-catalog-filter">
        <div className="mobile-filter-type">
          <a
            className={this.props.page == "bouquet" ? "active" : ""}
            href="/"
          >
            Букеты
          </a>
          <a
            className={this.props.page == "gifts" ? "active" : ""}
            href="/gifts"
          >
            Подарки
          </a>
          <a
            className={this.props.page == "special" ? "active" : ""}
            href="/special"
          >
            {this.state.pageTitle}
          </a>
        </div>
      </div>
    );
  }
}
export default UiCatalogFilter;
