import React, { Component } from 'react';
import { Container } from './navigation';
import EnvContext, {setEnvToStorage} from "./services/EnvContext";

function App() {
  const environment = process.env;
  const envData = {
    env: !(environment?.REACT_APP_ENV) ? 'dev': environment?.REACT_APP_ENV,
    apiUrl: !(environment?.REACT_APP_API_URL) ? 'https://spb.mateflowers.ru/api/public/api/v1' : environment?.REACT_APP_API_URL,
    uploadsUrl: !(environment?.REACT_APP_UPLOADS_URL) ? 'https://spb.mateflowers.ru/api/public/uploads/' : environment?.REACT_APP_UPLOADS_URL,
  };

  setEnvToStorage(envData);

  return (
      <EnvContext.Provider value={envData}>
        <Container />
      </EnvContext.Provider>
  );
}
export default App;